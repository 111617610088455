import {
  useRequestUserRolesMutation,
  UserPermission,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { PanelFooter } from "@earnnest-e2-frontend/platform-ui/src/mantine/Panel"
import { Box, Button, Flex, Space, Stack, Text, Title } from "@mantine/core"
import { notifications } from "@mantine/notifications"
import { useState } from "react"
import { RiCheckboxCircleFill } from "react-icons/ri"
import { useHistory } from "react-router-dom"

export default function BusinessFrontMatterScreen() {
  const history = useHistory()
  const [requestUserRoles] = useRequestUserRolesMutation({
    variables: {
      roles: [UserPermission.RequestEscrow],
    },
  })
  const [submitting, setSubmitting] = useState(false)
  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault()
        try {
          setSubmitting(true)
          await requestUserRoles()
          history.push("/business/profile")
        } catch (error) {
          notifications.show({
            color: "red",
            title: "Error",
            message: "Something went wrong",
          })
        } finally {
          setSubmitting(false)
        }
      }}>
      <Space h={38} />
      <Title size="h2" order={2}>
        Enroll your business
      </Title>
      <Space h={8} />
      <Text size="lg" color="gray.8">
        Enrollment takes just a few minutes. Here are a few things to have handy
        while enrolling your business:
      </Text>
      <Space h={40} />
      <Stack spacing={12}>
        <Flex direction="row" justify="start" align="start" gap={14}>
          <Box h={24} w={24} pt={1}>
            <RiCheckboxCircleFill size={24} />
          </Box>
          <Text size="lg">Business phone, email, and website</Text>
        </Flex>
        <Flex direction="row" justify="start" align="start" gap={14}>
          <Box h={24} w={24} pt={1}>
            <RiCheckboxCircleFill size={24} />
          </Box>
          <Text size="lg">Business EIN</Text>
        </Flex>
        <Flex direction="row" justify="start" align="start" gap={14}>
          <Box h={24} w={24} pt={1}>
            <RiCheckboxCircleFill size={24} />
          </Box>
          <Text size="lg">
            Business bank account information (if you want to receive earnest
            money deposits)
          </Text>
        </Flex>
      </Stack>
      <Space h={40} />
      <Box>
        <Title order={4}>Questions?</Title>
        <Space h={8} />
        <Text span size="lg">
          Call us (Mon-Fri 8am-8pm EST):{" "}
        </Text>
        <Text size="lg" component="a" href="tel:+1-888-870-2336" color="green">
          1-888-870-2336
        </Text>
      </Box>
      <PanelFooter>
        <Button size="lg" type="submit" loading={submitting}>
          Continue
        </Button>
      </PanelFooter>
    </form>
  )
}
